import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Image, Input, Radio, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios, { AxiosError } from 'axios';
import { decode as decodeHtml } from 'he';
import { cloneDeep, debounce, set } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ebayManagerApi, shippoApi, storageItemApi, storageProcessApi } from '../../../../apis';
import {
  CreateShipmentDto,
  CreateShipToBuyerDto,
  ParcelsDtoDistanceUnitEnum,
  ParcelsDtoMassUnitEnum,
  Rate,
  ShippingAddress,
} from '../../../../apis/client-axios';
import { SvgArrowDownSelect } from '../../../../components/@svg/SvgArrowDownSelect';
import SvgIconEditLarge from '../../../../components/@svg/SvgEditLarge';
import { SvgINoti } from '../../../../components/@svg/SvgINoti';
import ButtonAgree from '../../../../components/buttonCustom/ButtonAgree';
import ButtonAgreeWhite from '../../../../components/buttonCustom/ButtonAgreeWhite';
import ButtonArrowBack from '../../../../components/buttonCustom/ButtonArrowBack';
import ButtonCancel from '../../../../components/buttonCustom/ButtonCancel';
import CustomCheckbox from '../../../../components/checkbox';
import CustomInput from '../../../../components/input/CustomInput';
import { ModalInformation } from '../../../../components/ModalInformation';
import NotificationError from '../../../../components/notification/NotificationError';
import NotificationSuccess from '../../../../components/notification/NotificationSuccess';
import CustomSelect from '../../../../components/select/CustomSelect';
import { StyledModal } from '../../../../components/StyledModal';
import { USER_ROUTE_PATH } from '../../../../constants/router';
import { MainAppContext } from '../../../../containers/App/MainApp';
import { getLinkUserEbayNavigate } from '../../../../util/function-util';
import { TMessagesCarrier } from '../../return/storage-request';
import './ship2buyerRequest.scss';

type TDataRequest = {
  carrier: string;
  carrierPrice: number;
  errorInputHScode: boolean;
  errorInputItemPrice: boolean;
  HSCode: string;
  insurance: number;
  isInsurance: boolean;
  itemPrice: number;
  openModalInformation: boolean;
  orderAccountEbayId: number;
  orderBuyerEiasToken: string;
  orderItemId: string;
  orderItemName: string;
  orderItemPrice: number;
  orderItemPriceCurrency: string;
  orderItemSKU: string;
  // orderShippingAddress: string;
  // orderShippingAddress2: string;
  // orderShippingName: string;
  // orderShippingTelNo: string;
  // orderShippingZipCode: string;
  orderStatus: string;
  quantityPurchased: number;
  // shippingAddress: string;
  // shippingAddress2: string;
  // shippingName: string;
  shippingPlan: string;
  shippingRate: string;
  // shippingTelNo: string;
  // shippingZipCode: string;
  transactionId: string;
  shipByDate: string;
  confirm: boolean;
  imgVariant: string;
};
export default function Ship2BuyerRequest() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const itemStorage = searchParams.get('itemStorage');
  const orderId = searchParams.get('orderId');
  const itemId = searchParams.get('itemId');
  const userEbayId = searchParams.get('userEbayId');
  const IOSS = searchParams.get('IOSS');
  const phone = searchParams.get('phone');
  const lineItemID = searchParams.get('lineItemID');
  const shipByDate = searchParams.get('shipByDate');
  const { imageLineItem } = useContext(MainAppContext);
  const [modalEditAddress, setModalEditAddress] = useState<{
    isOpen: boolean;
    isChange: boolean;
    data: Omit<ShippingAddress, 'AddressID' | 'AddressOwner' | 'CountryName'>;
  }>({
    isOpen: false,
    isChange: false,
    data: {
      Name: '',
      Street1: '',
      Street2: '',
      CityName: '',
      PostalCode: '',
      StateOrProvince: '',
      Country: '',
      Phone: phone,
    },
  });
  const labelFormEdit = {
    Name: '名前',
    Street1: 'Street',
    Street2: 'Street (Line 2)',
    CityName: 'City',
    StateOrProvince: 'State Or Province',
    Country: 'Country',
    Phone: '電話番号',
    PostalCode: 'Postal code/zip',
  };
  const [formEditAddress] = useForm<typeof modalEditAddress.data>();
  const [dataRequest, setDataRequest] = useState<TDataRequest>({
    carrier: '',
    carrierPrice: 0,
    errorInputHScode: false,
    errorInputItemPrice: false,
    HSCode: '',
    insurance: 0,
    isInsurance: false,
    itemPrice: 0,
    openModalInformation: false,
    orderAccountEbayId: 0,
    orderBuyerEiasToken: '',
    orderItemId: '',
    orderItemName: '',
    orderItemPrice: 0,
    orderItemPriceCurrency: '',
    orderItemSKU: '',
    // orderShippingAddress: '',
    // orderShippingAddress2: '',
    // orderShippingName: '',
    // orderShippingTelNo: phone,
    // orderShippingZipCode: '',
    orderStatus: '',
    quantityPurchased: 0,
    // shippingAddress: '',
    // shippingAddress2: '',
    // shippingName: '',
    shippingPlan: '',
    shippingRate: '',
    // shippingTelNo: '',
    // shippingZipCode: '',
    transactionId: '',
    shipByDate: '',
    confirm: false,
    imgVariant: '',
  });
  const handleClickBack = () => {
    return dataRequest.confirm
      ? setDataRequest({ ...dataRequest, confirm: false })
      : navigate(`${USER_ROUTE_PATH.SHIP_BUYER_REQUEST}/${itemStorage}`);
  };
  const { mutate: CreateShipToBuyer, isPending: loadingCreateShip2Buyer } = useMutation({
    mutationKey: ['submit'],
    mutationFn: (data: CreateShipToBuyerDto) =>
      storageProcessApi.storageProcessControllerCreateShipToBuyerRequest(data),
    onSuccess: ({ data }) => {
      NotificationSuccess({ content: '発送依頼が登録されました。' });
      navigate(`${USER_ROUTE_PATH.RETURN_ITEM}`);
    },
    onError: ({ response: { data } }: AxiosError) => {
      // NotificationError({ content: (data as any)?.message });
    },
  });
  const {
    data: itemDetail,
    mutate: getOneItem,
    isPending: loadingGetOne,
  } = useMutation({
    mutationKey: [itemStorage],
    mutationFn: (id: string) => storageItemApi.storageItemControllerGetOneItemManagementUser(id),
    onError: ({ response: { data } }: AxiosError) => {},
  });
  const {
    data: carrier,
    mutate: getCarrier,
    isPending: loadingCarrier,
  } = useMutation({
    mutationKey: ['carrier'],
    mutationFn: (data: CreateShipmentDto) => shippoApi.shippoApiControllerCreateShipment(data),
    onSuccess: ({ data }) => {
      const listRates = Object.values(data.rates).filter((v) => v);
      if (!listRates.length) {
        // handleClickBack();
        data?.messages?.forEach((v: TMessagesCarrier) => {
          const split = v.text.split(': ');
          const content = `${v.source}: ${split[1] ?? split[0] ?? 'ERROR'}`;
          NotificationError({ content });
        });
      }
    },
    onError: ({ response: { data } }: AxiosError) => {
      // NotificationError({ content: (data as any)?.message });
      handleClickBack();
    },
  });
  const {
    data: orderEbay,
    mutate: getOneOrder,
    isPending: loadingGetOneOrder,
  } = useMutation({
    mutationKey: [orderId],
    mutationFn: (data: { orderId: string; userEbayId: string }) =>
      ebayManagerApi.ebayManagerControllerGetOneOrderReturnFromEbay(data.orderId, data.userEbayId),
    onError: ({ response: { data } }: AxiosError) => {},
  });
  const fetchApi = async () => await Promise.all([getOneItem(itemStorage), getOneOrder({ orderId, userEbayId })]);

  const mapAddress = (ShippingAddress: typeof modalEditAddress.data) => {
    const addressLine1 = ShippingAddress?.Street1 || '';
    const addressLine2 = ShippingAddress?.Street2 || '';
    const city = ShippingAddress?.CityName || '';
    const countryCode = ShippingAddress?.Country || '';
    const postalCode = ShippingAddress?.PostalCode || '';
    const stateOrProvince = ShippingAddress?.StateOrProvince || '';

    return `${addressLine1}, ${addressLine2 ? `${addressLine2},` : ''} ${city}, ${stateOrProvince} ${postalCode}, ${countryCode}`;
  };

  useEffect(() => {
    if (orderId && itemStorage && userEbayId) fetchApi();
  }, [orderId, itemStorage, userEbayId]);

  useEffect(() => {
    if (orderEbay?.data?.BuyerUserID && itemDetail?.data?.id) {
      const Transaction = orderEbay?.data?.TransactionArray?.Transaction as any; // type
      const item = Array.isArray(Transaction) ? Transaction?.find((v) => v.Item.ItemID === itemId) : Transaction;
      const orderItemPrice = +item?.TransactionPrice?.['_@ttribute'];
      const orderShippingTelNo =
        orderEbay?.data?.ShippingAddress?.Phone !== 'Invalid Request' ? orderEbay?.data?.ShippingAddress?.Phone : phone;

      setDataRequest({
        ...dataRequest,
        insurance: +((1.25 * orderItemPrice) / 100).toFixed(2),
        itemPrice: orderItemPrice,
        orderAccountEbayId: itemDetail?.data?.ebayReturns?.accountEbayId,
        orderBuyerEiasToken: orderEbay?.data?.EIASToken,
        orderItemId: item?.Item?.ItemID,
        orderItemName: decodeHtml(item?.Item?.Title || ''),
        orderItemPrice: orderItemPrice,
        orderItemPriceCurrency: item?.TransactionPrice?.currencyID,
        orderItemSKU: item?.Variation?.SKU,
        // orderShippingAddress: orderEbay?.data?.ShippingAddress?.Street1 || orderEbay?.data?.ShippingAddress?.Street2,
        // orderShippingAddress2: orderEbay?.data?.ShippingAddress?.Street2 ?? '',
        // orderShippingName: orderEbay?.data?.ShippingAddress?.Name,
        // orderShippingTelNo,
        // orderShippingZipCode: orderEbay?.data?.ShippingAddress?.PostalCode,
        orderStatus: orderEbay?.data?.OrderStatus,
        quantityPurchased: +item?.QuantityPurchased,
        // shippingAddress: orderEbay?.data?.ShippingAddress?.Street1 || orderEbay?.data?.ShippingAddress?.Street2,
        // shippingAddress2: orderEbay?.data?.ShippingAddress?.Street2 ?? '',
        // shippingName: orderEbay?.data?.ShippingAddress?.Name,
        // shippingTelNo: orderShippingTelNo,
        // shippingZipCode: orderEbay?.data?.ShippingAddress?.PostalCode,
        transactionId: item?.TransactionID,
        shipByDate: shipByDate,
        imgVariant: imageLineItem,
      });

      if (orderEbay?.data?.ShippingAddress) {
        const data = cloneDeep(modalEditAddress.data);
        Object.keys(modalEditAddress.data).forEach((key: keyof typeof modalEditAddress.data) => {
          key === 'Phone'
            ? (data[key] = orderShippingTelNo)
            : (data[key] = orderEbay?.data?.ShippingAddress?.[key] || '');
        });
        setModalEditAddress({ ...modalEditAddress, data });
      }
    }
  }, [orderEbay, itemDetail]);

  useEffect(() => {
    if (
      itemDetail?.data?.id &&
      dataRequest.orderItemId &&
      !dataRequest.errorInputHScode &&
      !dataRequest.errorInputItemPrice
    ) {
      let bodyGetShipment: CreateShipmentDto = {
        ebayAccountId: itemDetail?.data?.ebayReturns?.accountEbayId,
        address_to: {
          name: modalEditAddress.data.Name,
          street1: modalEditAddress.data.Street1 || modalEditAddress.data.Street2,
          street2: modalEditAddress.data.Street2 ?? '',
          city: modalEditAddress.data.CityName,
          state: modalEditAddress.data.StateOrProvince,
          zip: modalEditAddress.data.PostalCode,
          country: modalEditAddress.data.Country,
          phone: modalEditAddress.data.Phone,
        },
        parcels: [
          {
            distance_unit: ParcelsDtoDistanceUnitEnum.Cm,
            height: String(itemDetail?.data?.storageHeight),
            length: String(itemDetail?.data?.storageLength),
            mass_unit: ParcelsDtoMassUnitEnum.Kg,
            weight: String(itemDetail?.data?.storageWeight),
            width: String(itemDetail?.data?.storageWidth),
            metadata: '',
          },
        ],
        customs_declaration: {
          items: [
            {
              quantity: 1,
              description: itemDetail?.data?.itemEditName || '',
              mass_unit: 'kg',
              origin_country: 'US',
              hs_code: dataRequest.HSCode,
              sku_code: itemDetail?.data?.itemSku,
              net_weight: String(itemDetail?.data?.storageWeight),
              value_amount: String(dataRequest.itemPrice),
              value_currency: 'USD',
            },
          ],
        },
      };
      if (IOSS && IOSS != '') {
        bodyGetShipment = {
          ...bodyGetShipment,
          customs_declaration: {
            ...bodyGetShipment.customs_declaration,
            exporter_identification: {
              tax_id: {
                type: 'IOSS',
                number: IOSS.replace(/\s+/g, ''),
              },
            },
          },
        };
      }
      getCarrier(bodyGetShipment);
      setDataRequest({
        ...dataRequest,
        carrier: '',
        carrierPrice: 0,
        shippingPlan: '',
        shippingRate: '',
      });
    }
  }, [dataRequest.HSCode, dataRequest.itemPrice, itemDetail, modalEditAddress.data]);

  const handleGetShipment = debounce((value, inputName: string) => {
    if (inputName == 'HSCode') {
      if (value.length > 255) {
        setDataRequest({ ...dataRequest, errorInputHScode: true });
      } else {
        setDataRequest({
          ...dataRequest,
          carrier: '',
          carrierPrice: 0,
          shippingPlan: '',
          shippingRate: '',
          errorInputHScode: false,
          HSCode: value,
        });
      }
    } else if (inputName == 'ItemPrice') {
      if (+value < 0 || +value > 999999999999) {
        setDataRequest({ ...dataRequest, errorInputItemPrice: true });
      } else {
        const itemPrice = +value || dataRequest.orderItemPrice;
        setDataRequest({
          ...dataRequest,
          carrier: '',
          carrierPrice: 0,
          shippingPlan: '',
          shippingRate: '',
          itemPrice,
          errorInputItemPrice: false,
          insurance: +((1.25 * (itemPrice || dataRequest.orderItemPrice)) / 100).toFixed(2),
        });
      }
    }
  }, 1000);

  const { data: listCountries } = useQuery({
    queryKey: ['listCountries'],
    queryFn: () =>
      axios
        .get('https://restcountries.com/v3.1/all?fields=name,cca2')
        .then(({ data }) =>
          (data as [])
            .map((v: any) => ({ label: v?.name?.common, value: v?.cca2 }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ),
  });
  const handleFinishEditAddress = (data: typeof modalEditAddress.data) => {
    const isChange = Object.entries(modalEditAddress.data).some(([k, v]) => v !== data[k]);
    setModalEditAddress({ data: isChange ? data : { ...modalEditAddress.data }, isChange, isOpen: false });
  };
  return (
    <div className="request-ship-2-buyer">
      {itemDetail?.data && (
        <>
          <ButtonArrowBack onClick={handleClickBack} />
          <div className="request-ship-2-buyer-container">
            <p className="title">{dataRequest.confirm ? '発送依頼確認' : '配送情報'}</p>
            {dataRequest.confirm && <p>商品情報</p>}
            {dataRequest.confirm && (
              <div className="content-partitions info">
                <div className="content-partitions_img">
                  <Image preview={false} width={96} src={dataRequest.imgVariant} />
                </div>
                <div className="content-partitions_right">
                  <p className="name-item">{dataRequest?.orderItemName}</p>
                  <div className="content-partitions-item">
                    <div className="content-partitions-item-address top">
                      <p className="text">価格</p>
                      <span className="colon">:</span>
                    </div>
                    <span className="unit">$</span>
                    <span>{dataRequest?.itemPrice}</span>
                  </div>
                  <div className="content-partitions-item">
                    <div className="content-partitions-item-address top">
                      <p className="text">セラーSKU</p>
                      <span className="colon">:</span>
                    </div>
                    <span>{dataRequest?.orderItemSKU}</span>
                  </div>
                </div>
              </div>
            )}
            <div className="edit-detail-buyer">
              <p>バイヤー情報</p>
              {dataRequest.confirm ? null : (
                <button
                  onClick={() => {
                    formEditAddress.setFieldsValue(modalEditAddress.data);
                    setModalEditAddress({ ...modalEditAddress, isOpen: true });
                  }}
                >
                  <SvgIconEditLarge />
                </button>
              )}
            </div>
            {orderEbay?.data?.BuyerUserID && (
              <div className="content-partitions address">
                <div className="content-partitions-item">
                  <div className="content-partitions-item-address">
                    バイヤーID<span className="colon">:</span>
                  </div>
                  <a
                    className="link-user"
                    href={getLinkUserEbayNavigate(orderEbay?.data?.BuyerUserID, 'usr')}
                    target="_blank"
                  >
                    <span>{orderEbay?.data?.BuyerUserID}</span>
                  </a>
                </div>
                <div className="content-partitions-item">
                  <div className="content-partitions-item-address">
                    名前<span className="colon">:</span>
                  </div>
                  <span>{modalEditAddress.data.Name}</span>
                </div>
                <div className="content-partitions-item">
                  <div className="content-partitions-item-address">
                    住所<span className="colon">:</span>
                  </div>
                  <span>{mapAddress(modalEditAddress.data)}</span>
                </div>
                <div className="content-partitions-item">
                  <div className="content-partitions-item-address">
                    電話番号<span className="colon">:</span>
                  </div>
                  <span>{modalEditAddress.data.Phone}</span>
                </div>
              </div>
            )}
            <p>パッケージ情報</p>
            <div className="content-partitions size size-package">
              {orderEbay?.data?.ShippingAddress?.Country !== 'US' && (
                <div className="hs">
                  <div className="hs-price">
                    <div>HSコード</div>
                    <Input
                      style={{ borderColor: dataRequest.errorInputHScode ? 'red' : '' }}
                      disabled={dataRequest.confirm}
                      onChange={({ target: { value } }) => {
                        // if (value.length > 255) {
                        //   setDataRequest({ ...dataRequest, errorInputHScode: true });
                        // } else {
                        //   setDataRequest({ ...dataRequest, errorInputHScode: false, HSCode: value });
                        // }
                        handleGetShipment(value, 'HSCode');
                      }}
                    />
                  </div>
                  <div className="hs-price">
                    <div>申告価格（$）</div>
                    <Input
                      type="number"
                      style={{ borderColor: dataRequest.errorInputItemPrice ? 'red' : '' }}
                      disabled={dataRequest.confirm}
                      onChange={({ target: { value } }) => {
                        // if (+value < 0 || +value > 999999999999) {
                        //   setDataRequest({ ...dataRequest, errorInputItemPrice: true });
                        // } else {
                        //   const itemPrice = +value ?? dataRequest.orderItemPrice;
                        //   setDataRequest({
                        //     ...dataRequest,
                        //     itemPrice,
                        //     errorInputItemPrice: false,
                        //     insurance: +((1.25 * (itemPrice || dataRequest.orderItemPrice)) / 100).toFixed(2),
                        //   });
                        // }
                        handleGetShipment(value, 'ItemPrice');
                      }}
                    />
                  </div>
                  {IOSS && (
                    <div className="hs-price IOSS">
                      <div className="label">IOSS :</div>
                      <div className="IOSS">{IOSS}</div>
                    </div>
                  )}
                </div>
              )}
              <div className="size-detail">
                <div className="centimeter">梱包サイズ・重量</div>
                <div className="content-partitions-item-size">
                  <span>
                    <span className="content-partitions-item-size-item">
                      縦<span className="colon">:</span>
                    </span>
                    <span className="content-partitions-item-size-item-value">
                      {itemDetail?.data?.storageLength} cm
                    </span>
                  </span>
                  <span>
                    <span className="content-partitions-item-size-item">
                      横<span className="colon">:</span>
                    </span>
                    <span className="content-partitions-item-size-item-value">{itemDetail?.data?.storageWidth} cm</span>
                  </span>
                  <span>
                    <span className="content-partitions-item-size-item">
                      高さ<span className="colon">:</span>
                    </span>
                    <span className="content-partitions-item-size-item-value">
                      {itemDetail?.data?.storageHeight} cm
                    </span>
                  </span>
                  <span>
                    <span className="content-partitions-item-size-item">
                      重量<span className="colon">:</span>
                    </span>
                    <span className="content-partitions-item-size-item-value">
                      {itemDetail?.data?.storageWeight} kg
                    </span>
                  </span>
                </div>
              </div>
              {dataRequest.confirm ? null : (
                <div className="btn-group">
                  <ButtonAgreeWhite
                    type="small"
                    value="問い合わせ"
                    onClick={() =>
                      window.open(
                        `${USER_ROUTE_PATH.RETURN_ITEM_DETAIL}/${itemStorage}?orderId=${orderId}&itemId=${itemId}&userEbayId=${userEbayId}&itemStorage=${itemStorage}&phone=${phone}${IOSS ? `&IOSS=${IOSS}` : ''}`,
                      )
                    }
                  />
                  <div>梱包についてご要望がある場合お問い合わせください</div>
                </div>
              )}
            </div>
            <p>配送会社</p>
            <div className="content-partitions carrier">
              {!loadingCarrier ? (
                <Radio.Group>
                  <div className="carrier">
                    {carrier?.data?.rates
                      ? Object.values(carrier?.data?.rates)
                          .filter((v: Rate) => (dataRequest.confirm ? v?.provider === dataRequest.carrier : v))
                          .map((item, index) => (
                            <label
                              className={`carrier-item ${dataRequest.carrier === item.provider && 'active'} ${dataRequest.confirm && 'disabled'}`}
                              key={index}
                            >
                              {!dataRequest.confirm ? (
                                <Radio
                                  disabled={dataRequest.confirm}
                                  checked={dataRequest.carrier === item.provider}
                                  value={item.provider}
                                  id={item.provider}
                                  onChange={() => {
                                    setDataRequest({
                                      ...dataRequest,
                                      carrier: item.provider,
                                      carrierPrice: +item.amount,
                                      shippingPlan: item.servicelevel.displayName ?? item.servicelevel.name,
                                      shippingRate: item.objectId,
                                    });
                                  }}
                                />
                              ) : (
                                <div className="space"></div>
                              )}
                              <div className="d-flex align-items-center" style={{ gap: '12px' }}>
                                <Image
                                  preview={false}
                                  width={64}
                                  src={`/assets/icons/carrier/${item?.provider?.toLowerCase()}.png`}
                                />
                                <div>
                                  <span className="font-base color-title font-weight-600 font-size-16">
                                    {item?.provider}
                                  </span>
                                  <div>
                                    <span className="font-base color-sub-title font-weight-600 font-size-16">
                                      ${item?.amount}
                                    </span>
                                  </div>
                                  <div className="font-base color-sub-title font-weight-500 font-size-12 m-t-4 text-overflow-130">
                                    {item?.servicelevel?.displayName ?? item?.servicelevel?.name}
                                  </div>
                                  <div className="font-base color-cancel font-weight-500 font-size-10 m-t-2">
                                    About {item?.estimatedDays} days
                                  </div>
                                </div>
                              </div>
                              {item.provider === 'USPS' && (
                                <div
                                  className="carrier-item-information"
                                  onClick={() => setDataRequest({ ...dataRequest, openModalInformation: true })}
                                >
                                  <SvgINoti />
                                </div>
                              )}
                            </label>
                          ))
                      : null}
                  </div>
                </Radio.Group>
              ) : null}
            </div>
            {(dataRequest.confirm && dataRequest.isInsurance) || !dataRequest.confirm ? (
              <>
                <p>オプション</p>
                <div className="content-partitions insurance">
                  <div>
                    {!dataRequest.confirm ? (
                      <CustomCheckbox
                        className="insurance_item_checkbox"
                        onChange={({ target: { checked } }) => {
                          setDataRequest({ ...dataRequest, isInsurance: checked });
                        }}
                        disabled={dataRequest.confirm}
                      />
                    ) : (
                      <div></div>
                    )}
                    <label>保険料</label>
                  </div>
                  <div className="insurance_item_price">${dataRequest.insurance}</div>
                </div>
              </>
            ) : (
              <div className="content-partitions"></div>
            )}
            <div className="total">
              <div>合計金額</div>
              <div>
                ${(dataRequest.carrierPrice + (dataRequest.isInsurance ? dataRequest.insurance : 0)).toFixed(2)}
              </div>
            </div>
            <div className="request-ship-2-buyer-button-wrap">
              <ButtonCancel value="戻る" onClick={handleClickBack} />
              <ButtonAgree
                disabled={!dataRequest.carrier || dataRequest.errorInputHScode || dataRequest.errorInputItemPrice}
                value="確認"
                onClick={() => {
                  return dataRequest.confirm
                    ? CreateShipToBuyer({
                        hsCode: dataRequest.HSCode,
                        ioss: IOSS ?? '',
                        isInsurance: dataRequest.isInsurance,
                        itemPrice: dataRequest.itemPrice ?? dataRequest.orderItemPrice,
                        orderAccountEbayId: dataRequest.orderAccountEbayId,
                        orderBuyerCountry: modalEditAddress.data.Country,
                        orderBuyerEiasToken: dataRequest.orderBuyerEiasToken,
                        orderBuyerId: orderEbay?.data?.BuyerUserID,
                        orderId: orderId,
                        orderItemId: dataRequest.orderItemId,
                        orderItemName: decodeHtml(dataRequest.orderItemName || ''),
                        orderItemPrice: dataRequest.orderItemPrice,
                        orderItemPriceCurrency: dataRequest.orderItemPriceCurrency,
                        orderItemSKU: dataRequest.orderItemSKU,
                        orderShippingAddress: modalEditAddress.data.Street1,
                        orderShippingAddress2: modalEditAddress.data.Street2,
                        orderShippingName: modalEditAddress.data.Name,
                        orderShippingTelNo: modalEditAddress.data.Phone,
                        orderShippingZipCode: modalEditAddress.data.PostalCode,
                        orderStatus: dataRequest.orderStatus,
                        orderTransactionId: dataRequest.transactionId,
                        quantityPurchased: dataRequest.quantityPurchased,
                        shippingAddress: modalEditAddress.data.Street1,
                        shippingAddress2: modalEditAddress.data.Street2,
                        shippingCarrier: dataRequest.carrier,
                        shippingName: modalEditAddress.data.Name,
                        shippingPlan: dataRequest.shippingPlan,
                        shippingRate: dataRequest.shippingRate,
                        shippingTelNo: modalEditAddress.data.Phone,
                        shippingZipCode: modalEditAddress.data.PostalCode,
                        storageItemId: +itemStorage,
                        lineItemId: lineItemID,
                        shipByDate: dataRequest.shipByDate,
                      })
                    : setDataRequest({ ...dataRequest, confirm: true });
                }}
              />
            </div>
          </div>
          <ModalInformation
            content={
              'USPSは梱包サイズや重量が違うと、追加料金が発生しやすい傾向があります。USPSが1割ほど安いくらいの違いであれば、USPS以外をお勧めします。'
            }
            onCancel={() => setDataRequest({ ...dataRequest, openModalInformation: false })}
            open={dataRequest.openModalInformation}
            title={'USPS'}
          />

          <StyledModal
            children={
              <Form
                form={formEditAddress}
                onFinish={handleFinishEditAddress}
                className="request-ship-2-buyer__modal-edit-address-form"
                layout="vertical"
              >
                {Object.keys(modalEditAddress.data).map((key: keyof typeof modalEditAddress.data) => (
                  <Form.Item
                    name={key}
                    label={labelFormEdit[key]}
                    rules={
                      key === 'Phone'
                        ? [
                            {
                              message: '',
                              required: true,
                              transform: (value) => (value ? value.replace(/[^\d]/g, '') : value),
                              max: 100,
                              // validator: (_, value) => !/^[0-9]$/.test(value) ? Promise.resolve() : Promise.reject()
                            },
                          ]
                        : key === 'Street2'
                          ? undefined
                          : [
                              {
                                required: true,
                                transform: (value) => (value ? value.trim() : value),
                                message: '',
                                max: 100,
                              },
                            ]
                    }
                  >
                    {key === 'Country' ? (
                      <CustomSelect
                        className="item-country"
                        suffixIcon={<SvgArrowDownSelect />}
                        optionLabelProp="label"
                        options={listCountries}
                        placeholder="選択してください"
                        showSearch
                        optionFilterProp="label"
                      />
                    ) : (
                      <CustomInput
                        autoComplete="off"
                        type={key === 'Phone' ? 'number' : 'text'}
                        onKeyDown={(e) => {
                          if (key !== 'Phone') return;
                          if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') e.preventDefault();
                        }}
                      />
                    )}
                  </Form.Item>
                ))}
              </Form>
            }
            isOpen={modalEditAddress.isOpen}
            onCancel={() => setModalEditAddress({ ...modalEditAddress, isOpen: false })}
            modalProps={{
              title: 'バイヤー情報編集',
              className: 'request-ship-2-buyer__modal-edit-address',
              footer: (
                <>
                  <ButtonCancel
                    value="キャンセル"
                    onClick={() => setModalEditAddress({ ...modalEditAddress, isOpen: false })}
                  />
                  <ButtonAgreeWhite
                    value="元に戻す"
                    onClick={() => formEditAddress.setFieldsValue(orderEbay?.data?.ShippingAddress)}
                  />
                  <ButtonAgree value="保存" onClick={() => formEditAddress.submit()} />
                </>
              ),
            }}
          />
        </>
      )}
      {(loadingGetOne || loadingGetOneOrder || loadingCarrier || loadingCreateShip2Buyer) && (
        <div className="loading">
          <Spin />
        </div>
      )}
    </div>
  );
}

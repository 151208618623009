import './index.scss';
import { TButtonCustom } from './interface/interface';

const ButtonAgree = ({ value, ...props }: TButtonCustom) => {
  return (
    <button
      className={`button-agree ${props.disabled ? 'disabled' : ''} ${props.subClass}`}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {value}
    </button>
  );
};
export default ButtonAgree;

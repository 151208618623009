import { USER_ROUTE_NAME } from '../constants/router';

export const getMenuActiveIconName = (key: string): string => {
  switch (key) {
    case USER_ROUTE_NAME.RETURN_MANAGEMENT:
      return 'requestActive';
    case USER_ROUTE_NAME.RETURN_ITEM:
      return 'productActive';
    case USER_ROUTE_NAME.SHIPPING_LABEL_HISTORY:
      return 'shippingLabelHistoryActive';
    default:
      return '';
  }
};

export const disableMenu = (permission?: string[], requiredPer?: string) => {
  if (permission && requiredPer) {
    return !permission.includes(requiredPer);
  }
  return false;
};

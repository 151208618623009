const SvgNotification = () => (
  <span>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.12 20.28H23.0726C23.9162 20.28 24.6 19.5962 24.6 18.7527C24.6 18.3476 24.4391 17.9591 24.1526 17.6727L23.0726 16.5926C22.6676 16.1876 22.44 15.6382 22.44 15.0653L22.44 11.6083C22.44 6.85408 18.5859 3 13.8317 3C9.06732 3 5.20907 6.86985 5.22337 11.6342L5.23359 15.0394C5.23536 15.6275 4.99723 16.191 4.5742 16.5995L3.46293 17.6729C3.16708 17.9586 3 18.3523 3 18.7636C3 19.6011 3.67892 20.28 4.5164 20.28H9.48C9.48 22.6659 11.4141 24.6 13.8 24.6C16.1859 24.6 18.12 22.6659 18.12 20.28ZM21.9271 17.7382L22.849 18.66H4.77278L5.69965 17.7648C6.43995 17.0498 6.85667 16.0638 6.85358 15.0346L6.84336 11.6293C6.83175 7.76158 9.96392 4.62 13.8317 4.62C17.6912 4.62 20.82 7.74878 20.82 11.6083L20.82 15.0653C20.82 16.0678 21.2182 17.0293 21.9271 17.7382ZM16.5 20.28H11.1C11.1 21.7712 12.3088 22.98 13.8 22.98C15.2912 22.98 16.5 21.7712 16.5 20.28Z"
        fill="white"
      />
    </svg>
  </span>
);

export default SvgNotification;

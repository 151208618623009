const SvgLogoHome = () => (
  <span>
    <svg width="131" height="44" viewBox="0 0 131 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15799_3119)">
        <path
          d="M36.1153 0H7.88468C3.53009 0 0 3.53009 0 7.88468V36.1153C0 40.4699 3.53009 44 7.88468 44H36.1153C40.4699 44 44 40.4699 44 36.1153V7.88468C44 3.53009 40.4699 0 36.1153 0Z"
          fill="url(#paint0_linear_15799_3119)"
        />
        <path
          d="M32.3967 22.8115C31.7935 21.658 30.923 20.6787 29.785 19.8703C29.5767 19.7242 29.5456 19.4288 29.726 19.2516C30.4193 18.5831 30.9168 17.9302 31.2152 17.2898C31.6785 16.3197 31.9117 14.9735 31.9117 13.2448C31.9117 10.4497 30.8639 8.26093 28.7715 6.67218C26.8749 5.23888 24.4592 4.52379 21.5242 4.52379C18.5892 4.52379 16.1112 5.4845 14.1711 7.40903C12.3865 9.19366 11.4973 11.3047 11.4973 13.7485V40.58C11.4973 40.9656 12.0134 41.1459 12.2839 40.8536L16.3693 36.3734H23.713C26.5081 36.3734 28.7964 35.5029 30.581 33.7618C32.3656 32.0487 33.2548 29.6826 33.2548 26.6637C33.2548 25.2459 32.9657 23.9619 32.3905 22.8084L32.3967 22.8115Z"
          fill="white"
        />
        <path
          d="M16.6585 22.2053C17.5445 22.2053 18.2628 21.487 18.2628 20.601C18.2628 19.715 17.5445 18.9967 16.6585 18.9967C15.7725 18.9967 15.0542 19.715 15.0542 20.601C15.0542 21.487 15.7725 22.2053 16.6585 22.2053Z"
          fill="url(#paint1_linear_15799_3119)"
        />
        <path
          d="M20.9803 22.2053C21.8663 22.2053 22.5846 21.487 22.5846 20.601C22.5846 19.715 21.8663 18.9967 20.9803 18.9967C20.0942 18.9967 19.376 19.715 19.376 20.601C19.376 21.487 20.0942 22.2053 20.9803 22.2053Z"
          fill="url(#paint2_linear_15799_3119)"
        />
        <path
          d="M25.3019 22.2053C26.188 22.2053 26.9062 21.487 26.9062 20.601C26.9062 19.715 26.188 18.9967 25.3019 18.9967C24.4159 18.9967 23.6976 19.715 23.6976 20.601C23.6976 21.487 24.4159 22.2053 25.3019 22.2053Z"
          fill="url(#paint3_linear_15799_3119)"
        />
      </g>
      <path
        d="M73.2156 29.6406H70.9232C70.8004 29.6406 70.7027 29.5429 70.7027 29.42L70.6915 22.7525C70.6915 21.9372 70.3732 21.2587 69.7366 20.717C69.1 20.1977 68.2596 19.938 67.2125 19.938C66.3889 19.938 65.6601 20.1837 65.0235 20.6723C64.1775 21.3229 63.7559 22.2694 63.7559 23.5119C63.7559 24.6511 64.1133 25.5837 64.8253 26.304C65.4758 26.9546 66.2716 27.2813 67.2097 27.2813C67.7514 27.2813 68.2679 27.1221 68.751 26.8038C69.1363 26.5497 69.5747 26.1365 70.0661 25.5585C70.2001 25.4022 70.4542 25.4971 70.4542 25.7037V28.5321C70.4542 28.6075 70.4151 28.6801 70.3509 28.722C69.7226 29.1185 69.2731 29.3809 69.0023 29.5038C68.4271 29.7355 67.7682 29.8528 67.0227 29.8528C65.4647 29.8528 64.1133 29.2944 62.9741 28.1775C61.7093 26.9602 61.0782 25.405 61.0782 23.5119C61.0782 21.7501 61.606 20.3122 62.6614 19.1953C63.8006 17.9779 65.3753 17.3693 67.3856 17.3693C69.396 17.3693 70.8116 17.9249 71.9117 19.0334C72.928 20.0664 73.4362 21.3536 73.4362 22.8977V29.4256C73.4362 29.5485 73.3356 29.6462 73.2156 29.6462V29.6406Z"
        fill="url(#paint4_linear_15799_3119)"
      />
      <path
        d="M84.5794 17.4558C84.7022 17.4558 84.8027 17.5563 84.7999 17.6792L84.7776 21.7473C84.7692 22.9423 84.49 23.9614 83.94 24.8074C83.3313 25.7288 82.4267 26.3822 81.2344 26.7619C81.1423 26.7926 81.0781 26.8764 81.0781 26.9713V31.9748C81.0781 32.0976 80.9775 32.1954 80.8575 32.1954H78.554C78.4311 32.1954 78.3334 32.0949 78.3334 31.9748V26.9713C78.3334 26.8736 78.2692 26.7898 78.1771 26.7619C77.1272 26.4269 76.3063 25.9243 75.7144 25.2514C75.0164 24.4752 74.6674 23.4895 74.6674 22.2945V17.6792C74.6674 17.5563 74.7679 17.4586 74.8879 17.4586H77.144C77.2668 17.4586 77.3646 17.5591 77.3646 17.6792V21.7473C77.3646 22.5011 77.5237 23.0903 77.842 23.5147C78.2217 24.0424 78.8388 24.3048 79.6932 24.3048C80.4442 24.3048 81.0278 24.0563 81.4382 23.5593C81.8487 23.0875 82.0553 22.4956 82.0553 21.7919V17.6764C82.0553 17.5535 82.1558 17.4558 82.2759 17.4558H84.5766H84.5794Z"
        fill="url(#paint5_linear_15799_3119)"
      />
      <path
        d="M59.5426 22.1465C59.2104 21.5099 58.7301 20.9682 58.0991 20.5215C57.9846 20.4405 57.9679 20.2785 58.0684 20.1808C58.4509 19.8123 58.7245 19.4493 58.892 19.0975C59.1489 18.5614 59.2774 17.8187 59.2774 16.8638C59.2774 15.3197 58.6994 14.1108 57.5435 13.234C56.4964 12.4439 55.1618 12.0474 53.5396 12.0474C51.9173 12.0474 50.5492 12.5779 49.477 13.6417C48.4914 14.6273 48 15.7944 48 17.143V31.9663C48 32.1785 48.2848 32.2791 48.4356 32.1171L50.753 29.5763C50.7726 29.554 50.7865 29.5316 50.7949 29.5093C50.7949 29.5037 50.7977 29.4981 50.8005 29.4925C50.8033 29.4786 50.8089 29.4674 50.8089 29.4534C50.8089 29.4423 50.8145 29.4311 50.8145 29.4199L50.7921 17.4585C50.7921 16.699 51.049 16.0457 51.5599 15.504C52.0486 14.9847 52.7382 14.725 53.6317 14.725C54.4526 14.725 55.1366 14.9121 55.6811 15.2834C56.2004 15.6715 56.4601 16.2467 56.4601 17.0062C56.4601 17.626 56.2926 18.1426 55.9603 18.553C55.5331 19.0975 54.8742 19.3683 53.9835 19.3683H51.331C51.2081 19.3683 51.1104 19.4688 51.1104 19.5889V21.8449C51.1104 21.9678 51.2109 22.0655 51.331 22.0655H54.3437C55.159 22.0655 55.8096 22.2358 56.2982 22.5765C56.9041 23.0036 57.2056 23.6402 57.2056 24.4863C57.2056 25.3323 56.9348 25.9689 56.3903 26.3933C55.9324 26.759 55.3265 26.9405 54.5754 26.9405H51.3282C51.2053 26.9405 51.1076 27.041 51.1076 27.1611V29.4171C51.1076 29.54 51.2081 29.6377 51.3282 29.6377H54.7485C56.2926 29.6377 57.5574 29.1575 58.543 28.1942C59.5286 27.2477 60.0201 25.941 60.0201 24.2713C60.0201 23.4867 59.8609 22.7775 59.5426 22.1409V22.1465Z"
        fill="url(#paint6_linear_15799_3119)"
      />
      <path
        d="M109.868 29.6406H107.575C107.452 29.6406 107.355 29.5429 107.355 29.42L107.343 22.7524C107.343 21.9372 107.025 21.2587 106.389 20.717C105.752 20.1977 104.912 19.938 103.864 19.938C103.041 19.938 102.312 20.1837 101.675 20.6723C100.829 21.3229 100.408 22.2694 100.408 23.5119C100.408 24.6511 100.765 25.5837 101.477 26.304C102.128 26.9546 102.924 27.2813 103.862 27.2813C104.403 27.2813 104.92 27.1221 105.403 26.8038C105.788 26.5497 106.227 26.1365 106.718 25.5585C106.852 25.4022 107.106 25.4971 107.106 25.7037V28.5321C107.106 28.6075 107.067 28.6801 107.003 28.722C106.375 29.1185 105.925 29.3809 105.654 29.5038C105.079 29.7355 104.42 29.8528 103.675 29.8528C102.117 29.8528 100.765 29.2944 99.6261 28.1775C98.3612 26.9602 97.7302 25.405 97.7302 23.5119C97.7302 21.7501 98.2579 20.3121 99.3134 19.1953C100.453 17.9779 102.027 17.3692 104.038 17.3692C106.048 17.3692 107.464 17.9249 108.564 19.0333C109.58 20.0664 110.088 21.3536 110.088 22.8976V29.4256C110.088 29.5485 109.988 29.6462 109.868 29.6462V29.6406Z"
        fill="url(#paint7_linear_15799_3119)"
      />
      <path
        d="M119.71 29.5875C119.71 29.688 119.629 29.7662 119.528 29.7662L117.253 29.7327C115.374 29.7327 113.866 29.043 112.73 27.6637C111.744 26.4799 111.25 25.1648 111.25 23.7157C111.25 22.046 111.766 20.6388 112.799 19.4856C113.905 18.232 115.388 17.6065 117.253 17.6065H119.528C119.626 17.6065 119.707 17.6875 119.707 17.7852V20.0496C119.707 20.1474 119.626 20.2283 119.528 20.2283H117.557C116.759 20.2283 116.03 20.4852 115.371 21.0045C114.517 21.6635 114.089 22.5821 114.089 23.7659C114.089 24.5896 114.38 25.3295 114.958 25.9857C115.647 26.7535 116.546 27.136 117.66 27.136H119.526C119.623 27.136 119.704 27.217 119.704 27.3147V29.5875H119.71Z"
        fill="url(#paint8_linear_15799_3119)"
      />
      <path
        d="M130.823 17.6065C130.962 17.6065 131.046 17.7628 130.973 17.8801C130.217 19.1282 129.675 20.0161 129.354 20.5438C128.851 21.3563 128.432 21.951 128.092 22.3279C128.008 22.4201 128.05 22.5625 128.167 22.6071C128.843 22.8724 129.354 23.2745 129.695 23.8189C130.032 24.3438 130.2 24.9832 130.2 25.7343L130.222 29.5846C130.222 29.6824 130.144 29.7633 130.044 29.7633H127.676C127.578 29.7633 127.497 29.6824 127.497 29.5846V26.3905C127.497 25.765 127.38 25.282 127.145 24.9441C126.902 24.6231 126.481 24.4639 125.878 24.4639L124.032 24.4862C123.934 24.4862 123.856 24.5672 123.856 24.6649V29.5846C123.856 29.6824 123.775 29.7633 123.678 29.7633H121.307C121.209 29.7633 121.128 29.6824 121.128 29.5846V12.4718C121.128 12.374 121.209 12.2931 121.307 12.2931H123.678C123.775 12.2931 123.856 12.374 123.856 12.4718V21.6913C123.856 21.7891 123.937 21.87 124.035 21.87H124.61C125.071 21.8142 125.425 21.6774 125.668 21.4596C125.889 21.2474 126.202 20.7588 126.609 19.9909L127.966 17.693C127.997 17.64 128.056 17.6065 128.12 17.6065H130.825H130.823Z"
        fill="url(#paint9_linear_15799_3119)"
      />
      <path
        d="M97.6663 17.7015C97.6663 19.3544 97.1246 20.7365 96.0441 21.8478C95.0724 22.8585 93.9919 23.3639 92.8024 23.3639H89.4435C89.3458 23.3639 89.2648 23.2829 89.2648 23.1852V20.8147C89.2648 20.717 89.3458 20.636 89.4435 20.636H92.0262C93.0593 20.636 93.8159 20.2507 94.2934 19.4856C94.6229 18.9523 94.7876 18.3576 94.7876 17.6987C94.7876 16.9783 94.6089 16.3557 94.2459 15.8308C93.7294 15.0936 92.9336 14.7279 91.8615 14.7279C90.7893 14.7279 90.0019 15.1271 89.4993 15.9257C89.1475 16.5064 88.9716 17.2017 88.9716 18.017V29.5875C88.9716 29.6852 88.8907 29.7662 88.7929 29.7662H86.2828C86.1851 29.7662 86.1041 29.6852 86.1041 29.5875V17.9695C86.1041 16.2077 86.6849 14.7558 87.8436 13.611C88.9325 12.5388 90.2728 12 91.8615 12C93.358 12 94.6564 12.4663 95.7621 13.3988C97.0297 14.471 97.6663 15.9061 97.6663 17.6987V17.7015Z"
        fill="url(#paint10_linear_15799_3119)"
      />
      <defs>
        <linearGradient id="paint0_linear_15799_3119" x1="22" y1="0" x2="22" y2="44" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E65925" />
          <stop offset="0.19" stopColor="#E85F23" />
          <stop offset="1" stopColor="#F47920" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15799_3119"
          x1="16.6585"
          y1="18.9967"
          x2="16.6585"
          y2="22.2053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E65925" />
          <stop offset="0.19" stopColor="#E85F23" />
          <stop offset="1" stopColor="#F47920" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_15799_3119"
          x1="20.9803"
          y1="18.9967"
          x2="20.9803"
          y2="22.2053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E65925" />
          <stop offset="0.19" stopColor="#E85F23" />
          <stop offset="1" stopColor="#F47920" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_15799_3119"
          x1="25.3019"
          y1="18.9967"
          x2="25.3019"
          y2="22.2053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E65925" />
          <stop offset="0.19" stopColor="#E85F23" />
          <stop offset="1" stopColor="#F47920" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_15799_3119"
          x1="67.2572"
          y1="17.3637"
          x2="67.2572"
          y2="29.85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E65925" />
          <stop offset="0.19" stopColor="#E85F23" />
          <stop offset="1" stopColor="#F47920" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_15799_3119"
          x1="79.7323"
          y1="17.4558"
          x2="79.7323"
          y2="32.1954"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E65925" />
          <stop offset="0.19" stopColor="#E85F23" />
          <stop offset="1" stopColor="#F47920" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_15799_3119"
          x1="54.0114"
          y1="12.0474"
          x2="54.0114"
          y2="32.1953"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E65925" />
          <stop offset="0.19" stopColor="#E85F23" />
          <stop offset="1" stopColor="#F47920" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_15799_3119"
          x1="103.909"
          y1="17.3637"
          x2="103.909"
          y2="29.85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E65925" />
          <stop offset="0.19" stopColor="#E85F23" />
          <stop offset="1" stopColor="#F47920" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_15799_3119"
          x1="115.48"
          y1="17.6065"
          x2="115.48"
          y2="29.7634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E65925" />
          <stop offset="0.19" stopColor="#E85F23" />
          <stop offset="1" stopColor="#F47920" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_15799_3119"
          x1="126.065"
          y1="12.2959"
          x2="126.065"
          y2="29.7661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E65925" />
          <stop offset="0.19" stopColor="#E85F23" />
          <stop offset="1" stopColor="#F47920" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_15799_3119"
          x1="91.8866"
          y1="12.0028"
          x2="91.8866"
          y2="29.7662"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E65925" />
          <stop offset="0.19" stopColor="#E85F23" />
          <stop offset="1" stopColor="#F47920" />
        </linearGradient>
        <clipPath id="clip0_15799_3119">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export default SvgLogoHome;

const SvgNotificationFocus = () => (
  <span>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8 3C11.5568 3 9.40541 3.87912 7.81921 5.44396C6.23301 7.0088 5.34189 9.13118 5.34189 11.3442V15.5497C5.34206 15.7346 5.29862 15.917 5.21501 16.0825L3.14036 20.1748C3.03902 20.3747 2.99116 20.5968 3.00134 20.8201C3.01152 21.0435 3.0794 21.2605 3.19853 21.4506C3.31766 21.6408 3.48409 21.7977 3.682 21.9065C3.87991 22.0154 4.10275 22.0725 4.32933 22.0725H23.2707C23.4973 22.0725 23.7201 22.0154 23.918 21.9065C24.1159 21.7977 24.2823 21.6408 24.4015 21.4506C24.5206 21.2605 24.5885 21.0435 24.5987 20.8201C24.6088 20.5968 24.561 20.3747 24.4596 20.1748L22.3862 16.0825C22.3022 15.9171 22.2583 15.7347 22.2581 15.5497V11.3442C22.2581 9.13118 21.367 7.0088 19.7808 5.44396C18.1946 3.87912 16.0432 3 13.8 3ZM13.8 24.6C13.0501 24.6002 12.3186 24.3711 11.7062 23.9441C11.0938 23.5171 10.6306 22.9134 10.3805 22.2159H17.2195C16.9694 22.9134 16.5062 23.5171 15.8938 23.9441C15.2814 24.3711 14.5499 24.6002 13.8 24.6Z"
        fill="white"
      />
    </svg>
  </span>
);

export default SvgNotificationFocus;

import './index.scss';
import { TButtonCustom } from './interface/interface';

const ButtonAgreeWhite = ({ value, type = 'normal', ...props }: TButtonCustom) => {
  return (
    <button
      className={`button-agree-white ${type} ${props.disabled && 'disabled'} ${props.subClass && props.subClass}`}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {value}
    </button>
  );
};
export default ButtonAgreeWhite;
